import * as React from "react"
import { Link } from "gatsby"

import SEO from "../components/seo"
import Container from "../components/container"

const AboutPage = () => (
  <Container>
    <SEO title="About" />
    <h1 className="font-serif dark:text-slate-100 text-4xl mt-10 mb-5 font-bold underline">About</h1>
    <p className="my-4">
      Hi, my name is Andrew, I'm a Developer Support Specialist for an Australian based e-commerce platform.
      My day to day includes onboarding developers who wish to built out add-ons or API solutions, to running through
      our frontend templating language.
    </p>
    <p className="my-4">
      When I'm not working with our Developer Partners I'm improving and building out our library of online store 'themes' -
      built on a combination of Bootstrap, jQuery, and general HTML/CSS/JavaScript. With a passion for Frontend Development work,
      this extends into my free time where I am always looking for an opportunity to hone my Web Development skills.
    </p>
    <p className="my-4">
      Most recently I've started looking into React, a component based JavaScript framework that allows page layouts to be quickly built -
      assuming you've correctly broken down all your components to be as re-usable as possible.
    </p>
    <Link to="/" className="hover:text-cyan-300">Go back to the homepage</Link>
  </Container>
)

export default AboutPage
